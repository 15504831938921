import { Box, Stack, Typography } from "@mui/material";
import Link from "next/link";

export default function PortalTableRowUrban({ column, row }: any) {
  return (
    <Box alignItems="center" display={"flex"}>
      {typeof row[column.column] === "string" ? (
        <Typography
          sx={column.width ? { width: column.width } : {}}
          fontSize={14}
        >
          {row[column.column]}
        </Typography>
      ) : (
        <Stack>
          {row[column.column]?.map((urban: any) => {
            return (
              <Link href={`/urbans/${urban.id}`} key={urban.name}>
                <Typography
                  sx={
                    column.width
                      ? { width: column.width, color: "info.secondary" }
                      : { color: "info.secondary" }
                  }
                  fontSize={14}
                >
                  {urban.name}
                </Typography>
              </Link>
            );
          })}
        </Stack>
      )}
    </Box>
  );
}
