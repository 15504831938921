import { Box, Chip } from "@mui/material";

export default function PortalTableRowChip({
  column,
  row,
}: {
  column: any;
  row: any;
}) {
  const columnChipStyles =
    column.chip && typeof column.chip.style === "object"
      ? column.chip.style.filter(
          (s: any) => s.column.status === row[column.column]
        )
      : [];

  const setStyle = (): any => {
    if (typeof row[column.column] === "boolean") {
      return column.chip.style.filter(
        (s: any) => s.column.status === row[column.column]
      )[0].style;
    }
    if (
      column.chip.style.filter((c: any) => c.column === row[column.column])
        .length > 0
    )
      return column.chip.style.filter(
        (c: any) => c.column === row[column.column]
      )[0].style;
    return {};
  };

  return (
    <Box>
      <Chip
        size="small"
        label={
          typeof row[column.column] == "boolean"
            ? columnChipStyles.length > 0
              ? columnChipStyles[0].column.text
              : "Undefined"
            : row[column.column] !== null
              ? row[column.column]
              : "Undefined"
        }
        style={
          column.chip.style && column.chip.style.length > 0 ? setStyle() : {}
        }
        variant={column.chip.filled ? "filled" : "outlined"}
      />
    </Box>
  );
}
