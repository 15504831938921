import { Box, Rating, Tooltip, Typography } from "@mui/material";

export default function PortalTableRating({
  column,
  row,
}: {
  column: any;
  row: any;
}) {
  return (
    <>
      {row[column.column] && row[column.column] !== null ? (
        <Tooltip arrow title={row.feedback ?? "No Feedback"}>
          <Box display={"inline-flex"}>
            <Rating
              name="read-only"
              value={row[column.column]}
              readOnly
              sx={{ justifyContent: "center" }}
            />
          </Box>
        </Tooltip>
      ) : (
        <Typography fontSize={14} variant="caption">
          No Rating Given
        </Typography>
      )}
    </>
  );
}
