/* eslint-disable sonarjs/cognitive-complexity */
import ReportIcon from "@mui/icons-material/Report";
import { Box, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import StyledLink from "../StyledLink";

import OptionsMenu from "./OptionsMenu";
import PortalTableRating from "./PortalTableRating";
import PortalTableRowChip from "./PortalTableRowChip";
import PortalTableRowText from "./PortalTableRowText";
import PortalTableRowUrban from "./PortalTableRowUrban";

export default function PortalTableRow({
  row,
  data,
  options,
}: {
  row: any;
  data: any;
  key: string;
  options?: any;
}) {
  return (
    <TableRow>
      {data.columns.map((column: any, index: number) => {
        if (
          column.overflowCheck &&
          !column.fullName &&
          row[column.column] &&
          row[column.column].length > 37
        ) {
          return (
            <Tooltip
              key={"cell-tooltip-" + column + "-" + index}
              title={row[column.column]}
              placement="bottom-start"
            >
              <TableCell key={"cell-" + column + "-" + index}>
                <Typography
                  sx={{
                    width: column.width ?? 170,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  fontSize={14}
                >
                  {column.parentColumn
                    ? row[column.parentColumn] !== null
                      ? row[column.parentColumn][column.column]
                      : ""
                    : row[column.column]}
                </Typography>
              </TableCell>
            </Tooltip>
          );
        } else if (column.link) {
          return (
            <TableCell key={"cell-" + column + "-" + index}>
              <StyledLink
                href={
                  column.link.dynamicLink
                    ? column.link.dynamicLink + row[column.link.query]
                    : column.link
                }
              >
                {column.chip ? (
                  !row[column.column] ? null : (
                    <PortalTableRowChip column={column} row={row} />
                  )
                ) : (
                  <Box alignItems="center" display={"flex"}>
                    <PortalTableRowText
                      column={column}
                      row={row}
                      isLink={true}
                    />
                    {column.requestRejected && row.rejected ? (
                      <Tooltip
                        title="Request Rejected By Shop"
                        placement="bottom"
                      >
                        <ReportIcon sx={{ ml: 0.2 }} color="error" />
                      </Tooltip>
                    ) : null}
                  </Box>
                )}
              </StyledLink>
            </TableCell>
          );
        } else if (column.isUrban) {
          return (
            <TableCell key={"cell-" + column + "-" + index}>
              <PortalTableRowUrban column={column} row={row} />
            </TableCell>
          );
        } else if (column.rating) {
          return (
            <TableCell key={"cell-" + column + "-" + index}>
              <PortalTableRating column={column} row={row} />
            </TableCell>
          );
        } else {
          return (
            <TableCell key={"cell-" + column + "-" + index}>
              {column.chip ? (
                !row[column.column] ? null : (
                  <PortalTableRowChip column={column} row={row} />
                )
              ) : (
                <PortalTableRowText column={column} row={row} isLink={false} />
              )}
            </TableCell>
          );
        }
      })}
      {options ? (
        <TableCell>
          <OptionsMenu options={options} row={row} />
        </TableCell>
      ) : (
        <></>
      )}
    </TableRow>
  );
}
