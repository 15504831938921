import { Box, Card, Link, Stack, Typography } from "@mui/material";

function MobileTable({ data, columns }: { data: any[]; columns: any[] }) {
  return (
    <Box>
      {data?.map((row, index) => (
        <Stack p={1} gap={1} mt={3} mb={5} key={index}>
          <Card
            sx={{
              padding: 3,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {Object.keys(row).map((key) => {
              const column = columns.find((col) => col.column === key);
              const isLink = column?.link ?? false;

              return (
                <Box
                  key={key}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={3}
                >
                  <Box>
                    <Typography variant="h6">{column?.name ?? key}</Typography>
                  </Box>
                  <Box>
                    {isLink ? (
                      <Link
                        href={
                          column?.link?.dynamicLink
                            ? column?.link?.dynamicLink + row[key]
                            : column?.link
                        }
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: isLink ? "blue" : "",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {row[key]}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography variant="body1">
                        {row[key] ?? "N/A"}
                      </Typography>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Card>
        </Stack>
      ))}
    </Box>
  );
}

export default MobileTable;
