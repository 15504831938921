/* eslint-disable sonarjs/cognitive-complexity */
import { Typography } from "@mui/material";

import { getPrettyTime } from "../../lib/utils";

export default function PortalTableRowText({
  column,
  row,
  isLink,
}: {
  column: any;
  row: any;
  isLink: boolean;
}) {
  return (
    <Typography
      sx={
        column.width
          ? {
              width: column.width,
              color: isLink ? "info.secondary" : "",
              whiteSpace: "pre-wrap",
            }
          : { color: isLink ? "info.secondary" : "", whiteSpace: "pre-wrap" }
      }
      component={"div"}
    >
      {column.currency
        ? Intl.NumberFormat("en-us", {
            style: "currency",
            currency: "USD",
          }).format(row[column.column])
        : column.date
          ? getPrettyTime(row[column.column])
          : column.parentColumn
            ? row[column.parentColumn] !== null
              ? column.fullName
                ? row[column.parentColumn]?.firstName +
                  " " +
                  row[column.parentColumn]?.lastName
                : row[column.parentColumn][column.column]
              : ""
            : column.fullName
              ? row["firstName"] + " " + row["lastName"]
              : row[column.column]}
    </Typography>
  );
}
