/* eslint-disable sonarjs/cognitive-complexity */
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";

import MobileTable from "./MobileTable";
import PortalTableRow from "./PortalTableRow";

export default function PortalTable({
  data,
  handleSort,
  loading,
  searchTerm,
  heightOffset,
  options,
  responsive = false,
}: {
  responsive?: boolean;
  data: {
    data: any;
    columns: {
      column: string;
      parentColumn?: string;
      name: string;
      noSort?: boolean;
      fullName?: boolean;
      width?: number;
      overflowCheck?: boolean;
      currency?: boolean;
      date?: boolean;
      link?: string | { dynamicLink: string; query: string };
      chip?: {
        uppercase?: boolean;
        style?:
          | {
              backgroundColor: string;
              color: string;
            }
          | {
              column: string | { status: boolean | null; text: string } | null;
              style: {
                backgroundColor?: string;
                color: string;
                borderColor?: string;
              };
            }[];
        filled?: boolean;
      };
      requestRejected?: boolean;
      isUrban?: boolean;
      rating?: boolean;
    }[];
    count: number;
    orderBy: any;
    direction: string;
    offset: number;
    limit: number;
  };
  handleSort?: (param: any) => void;
  loading: boolean;
  searchTerm?: string;
  heightOffset?: number;
  options?: {
    label: string;
    onClick: any;
    color?: string;
    disabled?:
      | {
          checked?: any;
          ifEmpty?: string[];
          ifValue?: {
            column: string[];
            values: (string | boolean)[];
          };
        }
      | ((param: any) => boolean);
  }[];
}) {
  const isMobile = useMediaQuery("(max-width: 900px)");

  if (isMobile && responsive) {
    return <MobileTable data={data.data} columns={data.columns} />;
  }

  return (
    <Box
      sx={{
        maxHeight: !heightOffset
          ? "calc(100vh - 280px)"
          : "calc(100vh - " + (280 - heightOffset) + "px)",
        overflow: "auto",
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {data.columns.map((column: any, index: number) => {
              return (
                <TableCell key={column + "-" + index}>
                  {column.name}
                  {!handleSort || column.noSort ? null : column.parentColumn ? (
                    <IconButton
                      sx={{
                        ":hover": { color: "secondary.main" },
                        m: 0.5,
                        color:
                          Object.keys(data.orderBy)[0] ===
                            column.parentColumn &&
                          data.orderBy[column.parentColumn] === column.column
                            ? "secondary.main"
                            : "lightGrey",
                      }}
                      onClick={() =>
                        handleSort({ [column.parentColumn]: column.column })
                      }
                      size={"small"}
                    >
                      {Object.keys(data.orderBy)[0] === column.parentColumn &&
                      data.orderBy[column.parentColumn] === column.column &&
                      data.direction === "desc" ? (
                        <ArrowDownwardIcon sx={{ fontSize: 18, m: -0.5 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ fontSize: 18, m: -0.5 }} />
                      )}
                    </IconButton>
                  ) : (
                    <IconButton
                      sx={{
                        ":hover": { color: "secondary.main" },
                        m: 0.5,
                        color:
                          data.orderBy === column.column
                            ? "secondary.main"
                            : "lightGrey",
                      }}
                      onClick={() => handleSort(column.column)}
                      size={"small"}
                    >
                      {data.orderBy === column.column &&
                      data.direction === "desc" ? (
                        <ArrowDownwardIcon sx={{ fontSize: 18, m: -0.5 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ fontSize: 18, m: -0.5 }} />
                      )}
                    </IconButton>
                  )}
                </TableCell>
              );
            })}
            {options ? <TableCell key="options-cell"></TableCell> : <></>}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow key={"user-cell-id-load-2"}>
              <TableCell
                align="center"
                colSpan={
                  options ? data.columns.length + 1 : data.columns.length
                }
              >
                Loading list...
              </TableCell>
            </TableRow>
          ) : !loading && data?.data?.length > 0 ? (
            data.data.map((row: any, index: number) => {
              if (row !== null) {
                return (
                  <PortalTableRow
                    key={"table-row-" + index}
                    row={row}
                    data={data}
                    options={options}
                  />
                );
              }
            })
          ) : (
            <TableRow key={"user-cell-id-none-2"}>
              <TableCell
                align="center"
                colSpan={
                  options ? data.columns.length + 1 : data.columns.length
                }
              >
                {searchTerm === "" || searchTerm === undefined
                  ? "Currently there is no data available"
                  : "No data like " + searchTerm}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
}
