import { ReactNode } from "react";

import MuiLink from "@mui/material/Link";
import { LinkProps as NextLinkProps } from "next/dist/client/link";
import Link from "next/link";

type StyledLinkProps = React.ComponentProps<typeof MuiLink> &
  NextLinkProps & {
    children: ReactNode;
  };

export default function StyledLink({ children, ...rest }: StyledLinkProps) {
  return (
    <MuiLink component={Link} {...rest}>
      {children}
    </MuiLink>
  );
}
